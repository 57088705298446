@use '../imports' as *;

// DESKTOP
.txt-center {
	text-align: center;
}
.txt-left {
	text-align: left;
}
.txt-right {
	text-align: right;
}
.txt-ellipsize {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 400px;
}

// TABLET
.txt-center-tablet {
	@include responsive ("medium", "down") {
		text-align: center;
	}
}
.txt-left-tablet {
	@include responsive ("medium", "down") {
		text-align: left;
	}
}
.txt-right-tablet {
	@include responsive ("medium", "down") {
		text-align: right;
	}
}
.txt-ellipsize-tablet {
	@include responsive ("medium", "down") {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 400px;
	}
}

// MOBILE
.txt-center-mobile {
	@include responsive ("small", "down") {
		text-align: center;
	}
}
.txt-left-mobile {
	@include responsive ("small", "down") {
		text-align: left;
	}
}
.txt-right-mobile {
	@include responsive ("small", "down") {
		text-align: right;
	}
}
.txt-ellipsize-mobile {
	@include responsive ("small", "down") {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 400px;
	}
}