@use 'imports' as *;

.btn {
	cursor: pointer;
	border: none;
	width: fit-content;
	padding: $xxs $l;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	@include first-font-semibold;
	@include body-1;
	text-transform: uppercase;
	white-space: nowrap;
	@include basic-transition;

	@include responsive ("medium", "down") {
		@include body-2;
	}

	span,
	p {
		color: inherit;
		font-size: inherit;
	}

	&.btn-primary {
		color: $white;
		background-color: $first-color;
		height: 56px;

		&:not(:disabled):hover {
			background-color: hover-color($black);
		}
		&.details {
			@include responsive ("small", "down") {
				height: 40px;
			}
		}

		/* IMPORTANT: with ::after input type="button|submit" with class .btn-primary are no longer allowed (inputs cannot have child elements) */
		// &::after {
		//     content: '';
		//     display: inline-block;
		//     margin-left: $xxs;
		//     @include pseudo-element-image($icons + '/theme-icons/arrow-right-shadow-white.svg', 18px, 18px);
		// }
	}

	&.btn-secondary {
		color: $first-color;
		background-color: transparent;
		border: 1px solid $first-color;
		height: 56px;

		&:not(:disabled):hover {
			background-color: hover-color($black);
		}

		/* IMPORTANT: with ::after input type="button|submit" with class .btn-secondary are no longer allowed (inputs cannot have child elements) */
		// &::after {
		//     content: '';
		//     display: inline-block;
		//     margin-left: $xxs;
		//     @include pseudo-element-image($icons + '/theme-icons/arrow-right-shadow-pink.svg', 18px, 18px);
		// }
	}

	/* btn-primary w/o arrows */
	&.btn-third {
		color: $white;
		height: 48px;
		@include padding-0;
		background-image: linear-gradient(120deg, $third-color 0%, $first-color 100%);
		background-repeat: no-repeat;
		background-size: 100% 0.2em;
		background-position: 0 88%;
		transition: background-size 0.25s ease-in;
		&:hover {
		  background-size: 100% 88%;
		}
		&:before {
			@include pseudo-element-image($icons + '/general-icons/arrow-left-white.svg', 16px, 16px);
			background-repeat: no-repeat;
			background-size: contain;
			content: "";
			display: inline-block;
		}

		&:not(:disabled):hover {
			background-color: hover-color($black);
		}
	}

	&.btn-no-padding {
		padding: 0 !important;
	}

	&.btn-no-margin {
		margin: 0 !important;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.3;
	}

	&.btn-padding {
		padding: $xxs $xs;
	}
}

.link-underline {
	cursor: pointer;
}

.border-paypal.button {
	border: 1px solid $second-color;
	border-radius: $radius-small;
}

:after {
	box-sizing: border-box;
}

.label-input {
	position: relative;

	label {
		text-align: left;
		font-size: 14px;
		line-height: 24px;
		color: $white;
		@include first-font-semibold;

		span {
			@include first-font-regular;
		}
		a {
			@include first-font-semibold;
		}
	}

	input {
		border: 1px solid $blue-1;
		box-shadow: none;
		@include mb-xs;
		@include pt-xxs;
		color: $white;
		background-color: rgba($color: $white, $alpha: 0.07);
		@include first-font-regular;

		&:focus {
			border-color: $blue-2;
			box-shadow: none;
			background-color: $blue-1;

			+ label:not(.error) {
				color: $first-color;
			}
		}

        &:disabled, &:read-only {
            background-color: rgba($color: $white, $alpha: 0.07);
        }
	}

	input::placeholder {
		color: $grey-2;
		opacity: 1;
		@include first-font-regular;
	}

	textarea,
	select {
		border: none;
		box-shadow: none;
		border: 1px solid $blue-1;
		background-color: rgba($color: $white, $alpha: 0.07);
		@include mb-xs;
		@include first-font-regular;
		color: $white;

		&:focus {
			border-color: $blue-2;
			box-shadow: none;
			background-color: rgba($color: $white, $alpha: 0.07);
		}
	}

	input,
	select,
	textarea {
		&:disabled,
		&:disabled + label {
			cursor: not-allowed;
			opacity: 0.3;
		}
	}

	label.error {
		&,
		* {
			color: $danger;
		}
	}

	input,
	select,
	textarea {
		&.error {
			margin-bottom: 0;
		}

		&.error:not(:focus) {
			color: $danger;
			border-color: $danger;
		}

		&.error:not(:focus)::placeholder {
			color: rgba($color: $danger, $alpha: 0.7);
		}
	}
}

.checkbox-container {
	display: flex;
	align-items: flex-start;
	position: relative;

	.styled-checkbox {
		margin: 0;
		align-self: center;
	}

	.checkbox {
		opacity: 0;
	}

	.checkbox + label {
		position: relative;
		left: 8px;
		margin: 0;
		color: $white;
	}

	.checkbox + label::before {
		content: "";
		position: absolute;
		top: 5px;
		left: -22px;
		width: 16px;
		height: 16px;
		border: 1px solid $grey-2;
	}

	.checkbox:checked + label::after {
		content: "";
		position: absolute;
		top: 7px;
		left: -20px;
		width: 12px;
		height: 12px;
		background-color: $first-color;
	}

	&.radio {
		.checkbox + label::before {
			content: "";
			position: absolute;
			top: 5px;
			left: -22px;
			width: 16px;
			height: 16px;
			border: 1px solid $grey-2;
			border-radius: 50%;
		}
	
		.checkbox:checked + label::after {
			content: "";
			position: absolute;
			top: 7px;
			left: -20px;
			width: 12px;
			height: 12px;
			background-color: $first-color;
			border-radius: 50%;
		}

	}

	.checkbox.error + label {
		&,
		* {
			color: $danger;
		}

		&::before {
			border-color: $danger;
		}
	}

	.checkbox:disabled + label {
		cursor: not-allowed;
		opacity: 0.3;
	}
}

.email-input {
	width: 50%;
	@include responsive ("small", "down") {
		width: 100%;
	}
}

.radio-input {
	@include flex;
	@include direction-row;
	@include wrap;
}

.radio-container {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	position: relative;

	.radio {
		position: relative;
		top: 4px;
		opacity: 0;
	}

	// .radio + label {
	// 	border: 1px solid $grey-2;
	// 	margin: $xxs $xs $xxs 0;
	// 	padding: 0 $xxs;
	// 	min-width: 50px;
	// 	text-align: center;
	// }

	// .radio:checked + label {
	// 	border: 1px solid $first-color;
	// 	color: $first-color;
	// 	span {
	// 		color: $first-color;
	// 	}
	// }
	.radio + label::before {
		content: "";
		position: absolute;
		top: 5px;
		left: 0;
		width: 16px;
		height: 16px;
		border: 1px solid $grey-2;
		border-radius: 50%;
	}

	.radio:checked + label::after {
		content: "";
		position: absolute;
		top: 7px;
		left: 2px;
		width: 12px;
		height: 12px;
		background-color: $black;
		border-radius: 50%;
	}

	.radio.error + label {
		&,
		* {
			color: $danger;
		}

		&::before {
			border-color: $danger;
		}
	}

	.radio:disabled + label {
		cursor: not-allowed;
		opacity: 0.3;
	}
}

.search-container {
	position: relative;
	height: fit-content;
	width: 80%;
	border-bottom: 3px solid $first-color;

	.search-input {
		height: 56px;
		width: 100%;
		border: none;
		background-color: transparent;
		box-shadow: none;
		border-radius: 4px;
		padding-left: $s;
		padding-right: $l;
		font-size: 48px;

		@include responsive ("small", "down") {
			font-size: 32px;
			height: 38px;
		}

		&::placeholder {
			color: $grey-2;
			opacity: 1;
		}

		&::-webkit-search-cancel-button {
			-webkit-appearance: none;
		}
	}

	.search-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 56px;
		height: 56px;
		position: absolute;
		top: 0;
		right: 0;
		@include responsive ("small", "down") {
			width: 38px;
			height: 38px;
		}
	}

	.search-cancel {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		position: absolute;
		top: 0;
		right: 0;
		visibility: hidden;
	}

	&.has-cancel-btn .search-input:not(:placeholder-shown) {
		~ .search-btn {
			display: none;
		}
		~ .search-cancel {
			visibility: visible;
		}
	}
}
