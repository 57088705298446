@use '../imports' as *;

// DESKTOP
.first-font-regular {
	font-family: $first-font;
	font-weight: 400;
}
.first-font-medium {
	font-family: $first-font;
	font-weight: 500;
}
.first-font-semibold {
	font-family: $first-font;
	font-weight: 600;
}
.first-font-bold {
	font-family: $first-font;
	font-weight: 700;
}

// TABLET
.first-font-regular-tablet {
	@include responsive ("medium", "down") {
		font-family: $first-font;
		font-weight: 400;
	}
}
.first-font-medium-tablet {
	@include responsive ("medium", "down") {
		font-family: $first-font;
		font-weight: 500;
	}
}
.first-font-semibold-tablet {
	@include responsive ("medium", "down") {
		font-family: $first-font;
		font-weight: 600;
	}
}
.first-font-bold-tablet {
	@include responsive ("medium", "down") {
		font-family: $first-font;
		font-weight: 700;
	}
}

// MOBILE
.first-font-regular-mobile {
	@include responsive ("small", "down") {
		font-family: $first-font;
		font-weight: 400;
	}
}
.first-font-medium-mobile {
	@include responsive ("small", "down") {
		font-family: $first-font;
		font-weight: 500;
	}
}
.first-font-semibold-mobile {
	@include responsive ("small", "down") {
		font-family: $first-font;
		font-weight: 600;
	}
}
.first-font-bold-mobile {
	@include responsive ("small", "down") {
		font-family: $first-font;
		font-weight: 700;
	}
}