@use '../imports' as *;

// DESKTOP
.border {
	border: 1px solid $line-color;
}
.border-no-top {
	border-top: none;
}
.border-no-bottom {
	border-bottom: none;
}
.border-no-left {
	border-left: none;
}
.border-no-right {
	border-right: none;
}
.border-right {
	border-right: 1px solid $line-color;
}
.border-top {
	border-top: 1px solid $line-color;
}
.border-bottom {
	border-bottom: 1px solid $line-color;
}
.border-left {
	border-left: 1px solid $line-color;
}
.border-bottom-white {
	border-bottom: 1px solid $white;
}
.border-bottom-blue-1 {
	border-bottom: 1px solid $blue-1;
}
.border-bottom-first-color {
	border-bottom: 1px solid $first-color;
}
.bg-black {
	border-color: #090F13;
}
.bg-black-2 {
	border-color: #09151C;
}
.bg-blue-1 {
	border-color: #0E232E;
}
.bg-blue-2 {
	border-color: #05161F;
}
.bg-dark-grey {
	border-color: #474747;
}
.bg-grey-1 {
	border-color: #8C8C8C;
}
.bg-grey-2 {
	border-color: #C0C0C0;
}
.bg-light-grey {
	border-color: #F3F3F3;
}
.bg-line-color {
	border-color: #C1C1C1;
}
.bg-white {
	border-color: #FFFFFF;
}
.bg-first-color {
	border-color: #17ABE6;
}
.bg-second-color {
	border-color: #09CFDB;
}
.bg-third-color {
	border-color: #07E89E;
}
.bg-fourth-color {
	border-color: #FFB300;
}
.bg-fifth-color {
	border-color: #CE2A2A;
}
.radius-small {
	border-radius: $radius-small;
}
.radius-medium {
	border-radius: $radius-medium;
}
.radius-large {
	border-radius: $radius-large;
}

// TABLET
.border-tablet {
	@include responsive ("medium", "down") {
		border: 1px solid $line-color;
	}
}
.border-no-top-tablet {
	@include responsive ("medium", "down") {
		border-top: none;
	}
}
.border-no-bottom-tablet {
	@include responsive ("medium", "down") {
		border-bottom: none;
	}
}
.border-no-left-tablet {
	@include responsive ("medium", "down") {
		border-left: none;
	}
}
.border-no-right-tablet {
	@include responsive ("medium", "down") {
		border-right: none;
	}
}
.border-right-tablet {
	@include responsive ("medium", "down") {
		border-right: 1px solid $line-color;
	}
}
.border-top-tablet {
	@include responsive ("medium", "down") {
		border-top: 1px solid $line-color;
	}
}
.border-bottom-tablet {
	@include responsive ("medium", "down") {
		border-bottom: 1px solid $line-color;
	}
}
.border-left-tablet {
	@include responsive ("medium", "down") {
		border-left: 1px solid $line-color;
	}
}
.border-bottom-white-tablet {
	@include responsive ("medium", "down") {
		border-bottom: 1px solid $white;
	}
}
.border-bottom-blue-1-tablet {
	@include responsive ("medium", "down") {
		border-bottom: 1px solid $blue-1;
	}
}
.border-bottom-first-color-tablet {
	@include responsive ("medium", "down") {
		border-bottom: 1px solid $first-color;
	}
}
.bg-black-tablet {
	@include responsive ("medium", "down") {
		border-color: #090F13;
	}
}
.bg-black-2-tablet {
	@include responsive ("medium", "down") {
		border-color: #09151C;
	}
}
.bg-blue-1-tablet {
	@include responsive ("medium", "down") {
		border-color: #0E232E;
	}
}
.bg-blue-2-tablet {
	@include responsive ("medium", "down") {
		border-color: #05161F;
	}
}
.bg-dark-grey-tablet {
	@include responsive ("medium", "down") {
		border-color: #474747;
	}
}
.bg-grey-1-tablet {
	@include responsive ("medium", "down") {
		border-color: #8C8C8C;
	}
}
.bg-grey-2-tablet {
	@include responsive ("medium", "down") {
		border-color: #C0C0C0;
	}
}
.bg-light-grey-tablet {
	@include responsive ("medium", "down") {
		border-color: #F3F3F3;
	}
}
.bg-line-color-tablet {
	@include responsive ("medium", "down") {
		border-color: #C1C1C1;
	}
}
.bg-white-tablet {
	@include responsive ("medium", "down") {
		border-color: #FFFFFF;
	}
}
.bg-first-color-tablet {
	@include responsive ("medium", "down") {
		border-color: #17ABE6;
	}
}
.bg-second-color-tablet {
	@include responsive ("medium", "down") {
		border-color: #09CFDB;
	}
}
.bg-third-color-tablet {
	@include responsive ("medium", "down") {
		border-color: #07E89E;
	}
}
.bg-fourth-color-tablet {
	@include responsive ("medium", "down") {
		border-color: #FFB300;
	}
}
.bg-fifth-color-tablet {
	@include responsive ("medium", "down") {
		border-color: #CE2A2A;
	}
}
.radius-small-tablet {
	@include responsive ("medium", "down") {
		border-radius: $radius-small;
	}
}
.radius-medium-tablet {
	@include responsive ("medium", "down") {
		border-radius: $radius-medium;
	}
}
.radius-large-tablet {
	@include responsive ("medium", "down") {
		border-radius: $radius-large;
	}
}

// MOBILE
.border-mobile {
	@include responsive ("small", "down") {
		border: 1px solid $line-color;
	}
}
.border-no-top-mobile {
	@include responsive ("small", "down") {
		border-top: none;
	}
}
.border-no-bottom-mobile {
	@include responsive ("small", "down") {
		border-bottom: none;
	}
}
.border-no-left-mobile {
	@include responsive ("small", "down") {
		border-left: none;
	}
}
.border-no-right-mobile {
	@include responsive ("small", "down") {
		border-right: none;
	}
}
.border-right-mobile {
	@include responsive ("small", "down") {
		border-right: 1px solid $line-color;
	}
}
.border-top-mobile {
	@include responsive ("small", "down") {
		border-top: 1px solid $line-color;
	}
}
.border-bottom-mobile {
	@include responsive ("small", "down") {
		border-bottom: 1px solid $line-color;
	}
}
.border-left-mobile {
	@include responsive ("small", "down") {
		border-left: 1px solid $line-color;
	}
}
.border-bottom-white-mobile {
	@include responsive ("small", "down") {
		border-bottom: 1px solid $white;
	}
}
.border-bottom-blue-1-mobile {
	@include responsive ("small", "down") {
		border-bottom: 1px solid $blue-1;
	}
}
.border-bottom-first-color-mobile {
	@include responsive ("small", "down") {
		border-bottom: 1px solid $first-color;
	}
}
.bg-black-mobile {
	@include responsive ("small", "down") {
		border-color: #090F13;
	}
}
.bg-black-2-mobile {
	@include responsive ("small", "down") {
		border-color: #09151C;
	}
}
.bg-blue-1-mobile {
	@include responsive ("small", "down") {
		border-color: #0E232E;
	}
}
.bg-blue-2-mobile {
	@include responsive ("small", "down") {
		border-color: #05161F;
	}
}
.bg-dark-grey-mobile {
	@include responsive ("small", "down") {
		border-color: #474747;
	}
}
.bg-grey-1-mobile {
	@include responsive ("small", "down") {
		border-color: #8C8C8C;
	}
}
.bg-grey-2-mobile {
	@include responsive ("small", "down") {
		border-color: #C0C0C0;
	}
}
.bg-light-grey-mobile {
	@include responsive ("small", "down") {
		border-color: #F3F3F3;
	}
}
.bg-line-color-mobile {
	@include responsive ("small", "down") {
		border-color: #C1C1C1;
	}
}
.bg-white-mobile {
	@include responsive ("small", "down") {
		border-color: #FFFFFF;
	}
}
.bg-first-color-mobile {
	@include responsive ("small", "down") {
		border-color: #17ABE6;
	}
}
.bg-second-color-mobile {
	@include responsive ("small", "down") {
		border-color: #09CFDB;
	}
}
.bg-third-color-mobile {
	@include responsive ("small", "down") {
		border-color: #07E89E;
	}
}
.bg-fourth-color-mobile {
	@include responsive ("small", "down") {
		border-color: #FFB300;
	}
}
.bg-fifth-color-mobile {
	@include responsive ("small", "down") {
		border-color: #CE2A2A;
	}
}
.radius-small-mobile {
	@include responsive ("small", "down") {
		border-radius: $radius-small;
	}
}
.radius-medium-mobile {
	@include responsive ("small", "down") {
		border-radius: $radius-medium;
	}
}
.radius-large-mobile {
	@include responsive ("small", "down") {
		border-radius: $radius-large;
	}
}