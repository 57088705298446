@use '../imports' as *;

footer {
    .top-row {
        border-top: 1px solid $line-color;
        border-bottom: 1px solid $line-color;
    }

    .footer-column {
        @include responsive ("large", "up") {
            .title {
                pointer-events: none;
            }

            ul {
                display: block !important;
                height: 100% !important;
            }
        }

        @include responsive ("medium", "down") {
            border-bottom: 1px solid $line-color;

            .title {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transition: transform .4s ease-in-out;
                    @include pseudo-element-image($icons + '/theme-icons/arrow-down-first-color.svg', 9px, 5px);
                }

                &.open::after {
                    transform: rotate3d(1, 0, 0, 180deg);
                }
            }
        }
    }
}
