@use 'sass:map';
@use 'sass:list';

$breakpoints: (
	"mobile": 640px,
	"tablet": 1024px,
	"desktop": none
) !default;

@mixin responsive($breakpoint, $dir: "down") {
    @if $dir == "down" {
        $max-width: map.get($breakpoints, $breakpoint);
        @if $max-width == none {
            @content;
        }
        @else {
            @media screen and (max-width: #{$max-width}) {
                @content;
            }
        }
    }
    @else if $dir == "up" {
        $idx: list.index(($breakpoints), ($breakpoint map.get($breakpoints, $breakpoint)));
        @if $idx {
            $key: if($idx > 1, list.nth(list.nth($breakpoints, $idx - 1), 1), null);
            $min-width: if($key, map.get($breakpoints, $key) + 1px, 0);
            @media screen and (min-width: #{$min-width}) {
                @content;
            }
        }
    }
    @else if $dir == "only" {
        $idx: list.index(($breakpoints), ($breakpoint map.get($breakpoints, $breakpoint)));
        @if $idx {
            $key: if($idx > 1, list.nth(list.nth($breakpoints, $idx - 1), 1), null);
            $min-width: if($key, map.get($breakpoints, $key) + 1px, 0);
            $max-width: map.get($breakpoints, $breakpoint);
            @if $max-width == none {
                @media screen and (min-width: #{$min-width}) {
                    @content;
                }
            }
            @else {
                @media screen and (min-width: #{$min-width}) and (max-width: #{$max-width}) {
                    @content;
                }
            }
        }
    }
}