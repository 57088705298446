@use 'imports' as *;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box !important;
	color: $white;
	list-style-type: none;
	outline: 0 !important;
	@include first-font-regular;
	font-family: $first-font;
}

body {
	overflow-x: hidden;
	@include first-font-regular;
	background-color: $black !important;
}

hr {
	border: 0;
	border-top: 1px solid $line-color;
}

.row {
	max-width: 100rem !important;

	&.centered {
		max-width: 80rem !important;
	}

    &.expanded {
        max-width: none !important;
    }
}

a {
	text-decoration: none;
}

.block-scroll {
	overflow-y: hidden;
}

.wrapper {
	min-height: calc(100vh - 200px);
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
	&.top-100 {
		top: 100%;
	}
	&.top-0 {
		top: 0;
	}
	&.bottom-0 {
		bottom: 0;
	}
	&.right-0 {
		right: 0;
	}
	&.left-0 {
		left: 0;
	}
}

.position-initial {
	position: initial !important;
}

.width-100 {
	width: 100% !important;
}

.max-content {
	width: max-content !important;
}

.width-80 {
	width: 80%;
}

.width-50 {
	width: 50%;
}
.width-50-tablet {
	@include responsive ("medium", "down") {
		width: 50%;
	}
}

.width-50-mobile {
	@include responsive ("small", "down") {
		width: 50%;
	}
}

.width-fit-content {
	width: fit-content;
}

.height-fit-content {
	height: fit-content;
}

.max-width-50 {
	width: 50% !important;
}

.width-100-tablet {
	@include responsive ("medium", "down") {
		width: 100% !important;
	}
}

.width-100-mobile {
	@include responsive ("small", "down") {
		width: 100% !important;
	}
}

.height-100 {
	height: 100%;
}

.list-unstyled {
	list-style: none;
}

.block {
	display: block;
}
.inline {
	display: inline;
}
.inline-block {
	display: inline-block;
}

.hide {
	display: none;
}

.sticky {
	position: sticky;
}

.clickable {
	cursor: pointer;
}

.notClickable {
	cursor: default;
}

.sep {
	height: 100%;
	border-right: 1px solid $line-color;
}

.divide {
	border-top: 3px solid $white;
	margin: auto;
	@include mv-xs;
	max-width: 200px;
}

.search-input-fixer {
	border: none !important;
	border-bottom: 1px solid $black !important;
	border-radius: 0 !important;
	box-shadow: none !important;
}

@media screen and (max-width: 639px) {
	.hide-for-small {
		display: none;
	}
}

.dropdown {
	.dropdown-toggle {
		.dropdown-icon {
			transition: transform 0.4s;
			background-repeat: no-repeat;
		}
		
		&.open .dropdown-icon {
			transform: rotate3d(1, 0, 0, 180deg);
			background-repeat: no-repeat;
		}
	}

	.dropdown-content {
		display: none;
	}
}

.main-title {
	border-top: 3px solid $white;
	@include pt-xs;
	@include semibold;
	@include responsive ("medium", "down") {
		@include h3;
	}
}
.iti__country-list {
	background-color: $blue-1;
	border: 1px solid $blue-2;
}

.background-image {
    background-repeat: no-repeat;
    background-size: contain;
    
    .alt {
        display: none;
    }
}

.main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    > div[id*=container] {
        flex-grow: 1;
    }
}