@use '../imports' as *;

// DESKTOP
.regular {
	@include first-font-regular;
}
.medium {
	@include first-font-medium;
}
.semibold {
	@include first-font-semibold;
}
.bold {
	@include first-font-bold;
}

// TABLET
.regular-tablet {
	@include responsive ("medium", "down") {
		@include first-font-regular;
	}
}
.medium-tablet {
	@include responsive ("medium", "down") {
		@include first-font-medium;
	}
}
.semibold-tablet {
	@include responsive ("medium", "down") {
		@include first-font-semibold;
	}
}
.bold-tablet {
	@include responsive ("medium", "down") {
		@include first-font-bold;
	}
}

// MOBILE
.regular-mobile {
	@include responsive ("small", "down") {
		@include first-font-regular;
	}
}
.medium-mobile {
	@include responsive ("small", "down") {
		@include first-font-medium;
	}
}
.semibold-mobile {
	@include responsive ("small", "down") {
		@include first-font-semibold;
	}
}
.bold-mobile {
	@include responsive ("small", "down") {
		@include first-font-bold;
	}
}