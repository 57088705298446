@use '../imports' as *;

// DESKTOP
.underline {
	text-decoration: underline;
}
.line-through {
	text-decoration: line-through;
}

// TABLET
.underline-tablet {
	@include responsive ("medium", "down") {
		text-decoration: underline;
	}
}
.line-through-tablet {
	@include responsive ("medium", "down") {
		text-decoration: line-through;
	}
}

// MOBILE
.underline-mobile {
	@include responsive ("small", "down") {
		text-decoration: underline;
	}
}
.line-through-mobile {
	@include responsive ("small", "down") {
		text-decoration: line-through;
	}
}