@use '../imports' as *;

#product-container {
    @include pv-l;
    @include responsive ("small", "down") {
        @include pv-xxs;
    }

    .product-container {
        .product-text {
            .product-title {
                font-size: 25px;
                line-height: 32px;
            }

            .product-subtitle {
                margin-top: $xxs;
            }

            .product-subsubtitle {
                margin-top: $xxs;

                &,
                & span {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}

.profile-content {
    @include responsive ("small", "down") {
        padding-left: 0;
        @include mt-s;
    }

    hr {
        border: none;
        border-top: 1px solid $line-color;
        @include mt-s;
    }
}

// profile consent
.profile-privacy-row {
    h4 {
        color: $black;
        text-transform: uppercase;
        font-size: 14px;
        @include mb-xxs;
    }
}

.avaliable-every {
    @include first-font-regular;
}

.profile-nav {
    font-size: 14px;

    a,
    [type="submit"] {
        cursor: pointer;
        border: none;
        transition: all 0.15s ease-in-out;
        display: block;
        text-align: left;
        letter-spacing: 0.1em;
        margin-bottom: 0;
        text-transform: uppercase;
        padding: 16px 8px;
        @include first-font-bold;

        &.active,
        &:hover {
            color: $white;
            background: $blue-1;
        }
    }
}