@use '../imports' as *;

// DESKTOP
.h1, h1 {
	font-size: 88px;
	line-height: 96px;
	margin: $xxs 0 !important;
	@include first-font-bold;
}
.h2, h2 {
	font-size: 45px;
	line-height: 56px;
	margin: $xxs 0 !important;
	@include first-font-medium;
}
.h3, h3 {
	font-size: 38px;
	line-height: 48px;
	margin: $xxs 0 !important;
	@include first-font-medium;
}
.h4, h4 {
	font-size: 20px;
	line-height: 32px;
	margin: $xxs 0 !important;
	@include first-font-medium;
}
.body-1, p {
	font-size: 16px;
	line-height: 24px;
	margin-bottom: $xxs;
}
.body-2 {
	font-size: 14px;
	line-height: 24px;
	margin-bottom: $xxs;
}
.body-3 {
	font-size: 12px;
	line-height: 16px;
	margin-bottom: $xxs;;
}
.upper {
	text-transform: uppercase;
}
.lower {
	text-transform: lowercase;
}
.capitalize {
	text-transform: capitalize;
}
.initial {
	text-transform: initial;
}

// TABLET
.h1-tablet {
	@include responsive ("medium", "down") {
		font-size: 88px;
		line-height: 96px;
		margin: $xxs 0 !important;
		@include first-font-bold;
	}
}
.h2-tablet {
	@include responsive ("medium", "down") {
		font-size: 45px;
		line-height: 56px;
		margin: $xxs 0 !important;
		@include first-font-medium;
	}
}
.h3-tablet {
	@include responsive ("medium", "down") {
		font-size: 38px;
		line-height: 48px;
		margin: $xxs 0 !important;
		@include first-font-medium;
	}
}
.h4-tablet {
	@include responsive ("medium", "down") {
		font-size: 20px;
		line-height: 32px;
		margin: $xxs 0 !important;
		@include first-font-medium;
	}
}
.body-1-tablet {
	@include responsive ("medium", "down") {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: $xxs;
	}
}
.body-2-tablet {
	@include responsive ("medium", "down") {
		font-size: 14px;
		line-height: 24px;
		margin-bottom: $xxs;
	}
}
.body-3-tablet {
	@include responsive ("medium", "down") {
		font-size: 12px;
		line-height: 16px;
		margin-bottom: $xxs;;
	}
}
.upper-tablet {
	@include responsive ("medium", "down") {
		text-transform: uppercase;
	}
}
.lower-tablet {
	@include responsive ("medium", "down") {
		text-transform: lowercase;
	}
}
.capitalize-tablet {
	@include responsive ("medium", "down") {
		text-transform: capitalize;
	}
}
.initial-tablet {
	@include responsive ("medium", "down") {
		text-transform: initial;
	}
}

// MOBILE
.h1-mobile {
	@include responsive ("small", "down") {
		font-size: 88px;
		line-height: 96px;
		margin: $xxs 0 !important;
		@include first-font-bold;
	}
}
.h2-mobile {
	@include responsive ("small", "down") {
		font-size: 45px;
		line-height: 56px;
		margin: $xxs 0 !important;
		@include first-font-medium;
	}
}
.h3-mobile {
	@include responsive ("small", "down") {
		font-size: 38px;
		line-height: 48px;
		margin: $xxs 0 !important;
		@include first-font-medium;
	}
}
.h4-mobile {
	@include responsive ("small", "down") {
		font-size: 20px;
		line-height: 32px;
		margin: $xxs 0 !important;
		@include first-font-medium;
	}
}
.body-1-mobile {
	@include responsive ("small", "down") {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: $xxs;
	}
}
.body-2-mobile {
	@include responsive ("small", "down") {
		font-size: 14px;
		line-height: 24px;
		margin-bottom: $xxs;
	}
}
.body-3-mobile {
	@include responsive ("small", "down") {
		font-size: 12px;
		line-height: 16px;
		margin-bottom: $xxs;;
	}
}
.upper-mobile {
	@include responsive ("small", "down") {
		text-transform: uppercase;
	}
}
.lower-mobile {
	@include responsive ("small", "down") {
		text-transform: lowercase;
	}
}
.capitalize-mobile {
	@include responsive ("small", "down") {
		text-transform: capitalize;
	}
}
.initial-mobile {
	@include responsive ("small", "down") {
		text-transform: initial;
	}
}