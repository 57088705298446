@use '../imports' as *;

.termsAndConditions {
	display: flex;
	align-items: center;
	input {
		margin: 0;
	}
}

.tab-title {
	&.selected {
		text-decoration: underline;
		color: $first-color;
	}
}

.subscribe-image {
	background-size: contain;
	height: calc(100vh - 160px);
	background-position: center center;
	background-repeat: no-repeat;
}

.error {
	color: $danger;
}