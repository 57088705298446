@use '../imports' as *;

.alert-image {
    height: 65px;
   @include mb-s;

    &.success {
        width: 60px;
    }
}

.alert-box {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: $messagebox-z;
    background-color: rgba($color: $black, $alpha: 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: min(85vw, 25rem);
    min-height: 8rem;
    @include padding-m;
    .alert {
        @include medium;
        @include h4;
        color: $black;
        animation-name: alert;
        animation-duration: 200ms;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        @include mt-xxs;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .alert-danger,
    .alert-success {
        background-color: rgba($color: $white, $alpha: 0.97);
        text-align: center;
        border: 1px solid $black;
        padding: $xxl;
    }
}

@keyframes alert {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.block-scroll {
    margin: 0;
    height: 100%;
    overflow: hidden;
}
