@use '../imports' as *;

.paginator {
	.pagination-container {
		margin-top: $xs;
		.pagination {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			li a {
				display: inline-block;
				@include first-font-semibold;

				&:hover {
					background-color: transparent !important;
				}
			}

			li {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 32px;
				height: 32px;
				border: 1px solid $black;
				@include mh-xxxs;

				&:not(:first-child):not(:last-child):hover {
					background-color: $grey-1;
				}
			}

			li.active {
				border-color: $first-color;
				background-color: $first-color;

				a {
					color: $white;
				}
			}

			li:first-child,
			li:last-child {
				border: none;
			}
		}
	}
}
