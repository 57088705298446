@use '../imports' as *;

.breadcrumb {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include mt-xs;

    .breadcrumb-piece-container {
        .breadcrumb-piece {
            @include body-3;
        }
    }
}