@use '../imports' as *;

dialog#search-modal {
    border: none;
    padding: 0;
    margin: auto;
    background-color: transparent;
    width: 80vw;

    &::backdrop {
        background-color: rgba($color: $light-grey, $alpha: 0.95);
    }
    &.polyfill {
        border: none !important;
        padding: 0 !important;
        + .backdrop {
            background-color: rgba($color: $light-grey, $alpha: 0.95);
        }
    }

    form:not([method=dialog]) {
        position: relative;
        width: 100%;

        input {
            @include h3;
            @include h4-tablet;
            height: $xxxl;
            width: 100%;
            background: none;
            border: none;
            border-bottom: 1px solid $black;
            box-shadow: none;
            padding: $xxs;
            padding-right: ($xs * 2) + $m;
            margin: 0;
        }

        input::placeholder {
            @include h3;
            @include h4-tablet;
            color: $black;
            opacity: 1;
        }

        [type=submit] {
            position: absolute;
            right: $xs;
            top: $xs;
            width: $m;
            height: $m;
            padding: 0;
        }
    }
    
    .close-button {
        position: fixed;
        top: 5vh;
        right: 5vw;
    }
}