@use '../imports' as *;

.show-on-mobile-menu-open {
    @include responsive ("large", "up") {
        display: none !important;
    }
    @include responsive ("medium", "down") {
        &:not(.show) {
            display: none !important;
        }
    }
}

#menu {
    .menu {
        @include responsive ("medium", "up") {
            display: flex;
            align-items: center;
            justify-content: center;

            .menu {
                .menu-item {
                    a {
                        @include medium;
                    }
                }
            }
            
            .menu-item[data-level="1"] {
                @include pv-xxs;

                &:not(:last-child) {
                    margin-right: $s;
                }
                > a {
                    @include bold;
                }

                &:hover,
                &.selected {
                    > a,
                    > span {
                        color: $first-color;
                        @include bold;
                        border-bottom: 5px solid $first-color;
                        @include pb-xxs;
                    }
                }
            }
        }
    }
}

#menu-full-container {
    $mobile-menu-width: 100vw;
    bottom: 0;
    overflow-y: hidden;
    position: absolute;
    transition: transform 0.4s ease-in-out, background-color 0.4s;
    width: 100vw;
    background-color: $blue-2;

    &:not(.open, .hover-open) {
        display: none;
    }

    @include responsive ("medium", "down") {
        transform: translateX(-#{$mobile-menu-width});
        &.open, &.hover-open {
            transform: translateX(0);
            top: 70px;
            height: calc(100vh - 70px);

            .menu-item[data-level="1"].mobile.header {
                opacity: 1;
            }
        }
        #menu-full {
            position: fixed;
            width: $mobile-menu-width;
            top: 0px;
            bottom: 0;
            transition: transform 0.4s ease-in-out, background-color 0.4s;
            display: block;
            height: calc(100vh - 70px);
            overflow-y: auto;
            background-color: $black;

            .menu-item[data-level="1"] {
                display: block;
                position: relative;
                width: 100%;
                &.selected {
                    a {
                        color: $first-color;
                        @include bold;
                        background-color: $black-2;
                    }
                }

                &.open, &.hover-open {
                    max-height: calc(100vh - 70px - 88px);
                    overflow-y: hidden;
                    background-color: $blue-2;

                    > a,
                    > span {
                        pointer-events: none;
                        @include semibold;
                        color: $first-color;
                        @include mb-0;

                        &::after {
                            display: none;
                        }
                    }
                }

                > a,
                > span {
                    position: relative;
                    display: flex;
                    width: 100%;
                    @include pv-xs;
                    @include ph-xxs;
                    @include body-1;
                    @include semibold;
                    text-transform: capitalize;

                    &:active {
                        background-color: $blue-2;
                    }
                }

                &.has-submenu {
                    > a,
                    > span {
                        &::after {
                            position: absolute;
                            right: $xxs;
                            top: calc(50% - 7px);
                            transition: transform 0.2s;
                            @include pseudo-element-image(
                                $icons + "/theme-icons/arrow-down-first-color.svg",
                                14px,
                                14px
                            );
                            content: "";
                        }
                    }
                }

                .submenu {
                    max-height: calc(100vh - 70px - 88px - 57px);
                    overflow-y: scroll;
                    background-color: $blue-2;
                    border-left: 1px solid $first-color;
                    @include mv-xxs;
                    &:not(.open, .hover-open) {
                        display: none;
                    }
                }

                &.mobile:not(.header) {
                    > a::before,
                    > span::before {
                        content: "";
                        display: inline-block;
                        @include mr-xxs;
                    }
                }

                &.mobile.header {
                    background-color: $black;
                    padding-bottom: $xs;
                    border: none;
                    transition: opacity 0.4s linear;
                    opacity: 0;

                    > a,
                    > span {
                        display: none;
                    }

                    .submenu {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        @include padding-xs;

                        .menu-item {
                            > a,
                            > span {
                                border-bottom: none;
                                @include padding-0;
                            }
                        }

                        .menu-item.button {
                            background-color: $first-color;
                            padding: $xxs $xs;

                            > a,
                            > span {
                                @include semibold;
                                color: $white;
                            }
                        }

                        .menu-item.link {
                            > a,
                            > span {
                                @include semibold;
                                @include upper;
                                @include body-2;
                            }
                        }
                    }
                }
            }

            .menu-item[data-level="2"] {

                &.selected {
                    a {
                        color: $first-color;
                        @include bold;
                    }
                }

                &.open, &.hover-open {
                    > a,
                    > span {
                        @include semibold;
                        color: $second-color;
                        &::after {
                            transform: rotate3d(1, 0, 0, 180deg);
                        }
                    }
                }

                > a,
                > span {
                    position: relative;
                    display: block;
                    width: 100%;
                    @include padding-xxs;
                    @include body-2;
                    @include semibold;
                    text-transform: capitalize;

                    &:active {
                        background-color: $blue-1;
                    }
                }

                &.has-submenu {
                    > a,
                    > span {
                        &::after {
                            position: absolute;
                            right: $xxs;
                            top: calc(50% - 3.5px);
                            transition: transform 0.2s;
                            @include pseudo-element-image(
                                $icons + "/theme-icons/arrow-down-first-color.svg",
                                14px,
                                7px
                            );
                            content: "";
                        }
                        &::before {
                            transform: rotate3d(1, 0, 0, 180deg);
                        }
                    }
                }

                .submenu {
                    display: none;
                    border-left: 4px solid $second-color;
                }
            }
        }
    }
    @include responsive ("large", "up") {
        height: calc(100vh - 96px);
        top: 100%;

        #menu-full {
            $menu-width: 22vw;
            background-color: $black;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            width: $menu-width;
            min-height: 100%;
            padding: $xl 0;
            position: relative;
            
            .menu-item[data-level="1"] {
                display: block;
                width: 100%;
                @include mr-0;
                @include mb-s;
                @include ph-l;
                
                > a,
                > span {
                    cursor: pointer;
                    @include margin-0;
                    @include h4;
                    border-bottom: 5px solid transparent;
                }

                &.has-full-submenu {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &::after {
                        display: inline-block;
                        right: $xxs;
                        top: calc(50% - 7px);
                        transition: transform 0.2s;
                        @include pseudo-element-image($icons + "/theme-icons/arrow-right-first-color.svg", 7px, 14px);
                        content: "";
                    }
                }

                &.profile {
                    margin-top: auto;
                }

                &:hover,
                &.selected,
                &.open, 
                &.hover-open{
                    > a,
                    > span {
                        border-bottom: 5px solid $first-color;
                        color: $first-color;
                        @include bold;
                    }
                }

                &.selected > .submenu {
                    display: block !important;
                }

                > .submenu {
                    position: absolute;
                    left: $menu-width;
                    width: 100%;
                    height: 100%;
                    top: 8px;
                    padding: $xl $l;
                    .menu-item[data-level="2"] {
                        @include mb-s;

                        > a, > span {
                            cursor: pointer;
                            @include h4;
                            @include medium;
                            color: $white;

                            &:focus {
                                color: $first-color;
                            }
                        }

                        &.big-text span {
                            position: fixed;
                            bottom: 0px;
                            right: 0px;
                            font-size: 210px;
                            line-height: 224px;
                            color: $white;
                            @include text-outline-1;
                            transition: 3.3s;
                            transition-timing-function: linear;
                            mix-blend-mode: soft-light;
                            @include semibold;
                        }

                        &.selected > a {
                            color: $first-color;
                            @include semibold;
                        }
                    }
                    &:not(.open, .hover-open) {
                        display: none;
                    }
                }
            }
        }
    }
}
