@use '../imports' as *;

.swiper-container {
    .swiper-button-next, .swiper-button-prev {
        color: $black;
    }

    .swiper-slide{
        &.product-img img {
            width: 100%;
        }
    }
}

.swiper-slide {
    padding-bottom: 1px; // bottom border is hidden sometimes without this
}

[id*=category-swiper] {
    .swiper-wrapper {
        @include pv-xxl;
    }
}