@use '../imports' as *;

#home-container {
	.under-menu-banner {
		height: calc(100vh - 96px);

		h1 {
			font-size: 117px;
			line-height: 128px;
			@include bold;
			@include pl-s;
			@include responsive ("medium", "down") {
				font-size: 60px;
				line-height: 72px;
			}
		}
		.vertical-text {
			writing-mode: vertical-rl;
			text-orientation: mixed;
			text-transform: uppercase;
			@include bold;
			letter-spacing: 23px;
			padding: $s;
			color: $first-color;
			@include responsive ("medium", "down") {
				@include body-3;
				padding: $xxs;
			}
		}
	}
}

@keyframes fade-in {
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
  }
.advantagesContainer {
	opacity: 0;
	&.advantagesContainerAnimation {
		opacity: 1;
		p.h1 {
			@include mb-m;
			@include responsive ("medium", "down") {
				@include h2;
			}
		}
		p.h3 {
			@include mb-m;
			@include responsive ("medium", "down") {
				@include h4;
			}
		}
		.advantages {
			animation: fade-in 1.2s ease-in-out both;

			&.text-2 {
				animation-delay: 0.5s;
			}
			&.text-3 {
				animation-delay: 1s;
			}
			&.text-4 {
				animation-delay: 1.5s;
			}
			&.text-5 {
				animation-delay: 2s;
			}
			&.text-6 {
				animation-delay: 2.5s;
			}
			&.text-7 {
				animation-delay: 3s;
			}
			&.text-8 {
				animation-delay: 3.5s;
			}
			&.text-9 {
				animation-delay: 4s;
			}
			&.text-10 {
				animation-delay: 4.5s;
			}
			&.text-11 {
				animation-delay: 5s;
			}
			&.text-12 {
				animation-delay: 5.5s;
			}
		}
	}
}