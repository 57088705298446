@use '../imports' as *;

// DESKTOP
.bg-black {
	background-color: #090F13;
}
.bg-black-2 {
	background-color: #09151C;
}
.bg-blue-1 {
	background-color: #0E232E;
}
.bg-blue-2 {
	background-color: #05161F;
}
.bg-dark-grey {
	background-color: #474747;
}
.bg-grey-1 {
	background-color: #8C8C8C;
}
.bg-grey-2 {
	background-color: #C0C0C0;
}
.bg-light-grey {
	background-color: #F3F3F3;
}
.bg-line-color {
	background-color: #C1C1C1;
}
.bg-white {
	background-color: #FFFFFF;
}
.bg-first-color {
	background-color: #17ABE6;
}
.bg-second-color {
	background-color: #09CFDB;
}
.bg-third-color {
	background-color: #07E89E;
}
.bg-fourth-color {
	background-color: #FFB300;
}
.bg-fifth-color {
	background-color: #CE2A2A;
}
.bg-danger {
	background-color: $danger;
}
.bg-success {
	background-color: $success;
}

// TABLET
.bg-black-tablet {
	@include responsive ("medium", "down") {
		background-color: #090F13;
	}
}
.bg-black-2-tablet {
	@include responsive ("medium", "down") {
		background-color: #09151C;
	}
}
.bg-blue-1-tablet {
	@include responsive ("medium", "down") {
		background-color: #0E232E;
	}
}
.bg-blue-2-tablet {
	@include responsive ("medium", "down") {
		background-color: #05161F;
	}
}
.bg-dark-grey-tablet {
	@include responsive ("medium", "down") {
		background-color: #474747;
	}
}
.bg-grey-1-tablet {
	@include responsive ("medium", "down") {
		background-color: #8C8C8C;
	}
}
.bg-grey-2-tablet {
	@include responsive ("medium", "down") {
		background-color: #C0C0C0;
	}
}
.bg-light-grey-tablet {
	@include responsive ("medium", "down") {
		background-color: #F3F3F3;
	}
}
.bg-line-color-tablet {
	@include responsive ("medium", "down") {
		background-color: #C1C1C1;
	}
}
.bg-white-tablet {
	@include responsive ("medium", "down") {
		background-color: #FFFFFF;
	}
}
.bg-first-color-tablet {
	@include responsive ("medium", "down") {
		background-color: #17ABE6;
	}
}
.bg-second-color-tablet {
	@include responsive ("medium", "down") {
		background-color: #09CFDB;
	}
}
.bg-third-color-tablet {
	@include responsive ("medium", "down") {
		background-color: #07E89E;
	}
}
.bg-fourth-color-tablet {
	@include responsive ("medium", "down") {
		background-color: #FFB300;
	}
}
.bg-fifth-color-tablet {
	@include responsive ("medium", "down") {
		background-color: #CE2A2A;
	}
}
.bg-danger-tablet {
	@include responsive ("medium", "down") {
		background-color: $danger;
	}
}
.bg-success-tablet {
	@include responsive ("medium", "down") {
		background-color: $success;
	}
}

// MOBILE
.bg-black-mobile {
	@include responsive ("small", "down") {
		background-color: #090F13;
	}
}
.bg-black-2-mobile {
	@include responsive ("small", "down") {
		background-color: #09151C;
	}
}
.bg-blue-1-mobile {
	@include responsive ("small", "down") {
		background-color: #0E232E;
	}
}
.bg-blue-2-mobile {
	@include responsive ("small", "down") {
		background-color: #05161F;
	}
}
.bg-dark-grey-mobile {
	@include responsive ("small", "down") {
		background-color: #474747;
	}
}
.bg-grey-1-mobile {
	@include responsive ("small", "down") {
		background-color: #8C8C8C;
	}
}
.bg-grey-2-mobile {
	@include responsive ("small", "down") {
		background-color: #C0C0C0;
	}
}
.bg-light-grey-mobile {
	@include responsive ("small", "down") {
		background-color: #F3F3F3;
	}
}
.bg-line-color-mobile {
	@include responsive ("small", "down") {
		background-color: #C1C1C1;
	}
}
.bg-white-mobile {
	@include responsive ("small", "down") {
		background-color: #FFFFFF;
	}
}
.bg-first-color-mobile {
	@include responsive ("small", "down") {
		background-color: #17ABE6;
	}
}
.bg-second-color-mobile {
	@include responsive ("small", "down") {
		background-color: #09CFDB;
	}
}
.bg-third-color-mobile {
	@include responsive ("small", "down") {
		background-color: #07E89E;
	}
}
.bg-fourth-color-mobile {
	@include responsive ("small", "down") {
		background-color: #FFB300;
	}
}
.bg-fifth-color-mobile {
	@include responsive ("small", "down") {
		background-color: #CE2A2A;
	}
}
.bg-danger-mobile {
	@include responsive ("small", "down") {
		background-color: $danger;
	}
}
.bg-success-mobile {
	@include responsive ("small", "down") {
		background-color: $success;
	}
}