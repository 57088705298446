@use '../imports' as *;

.cms-wrapper {
    @include mb-xxl;

    p {
        @include mb-xxs;
    }

    ul,
    ol {
        padding: 0 0 16px 24px;
    }

    ol li {
        list-style-type: decimal;
    }

    ul li {
        list-style-type: disc;
    }
}

dialog#news-popup {
    &::backdrop {
        background-color: rgba($color: $black, $alpha: 0.95);
        width: 100%;
    }
    &.polyfill {
        border: none !important;
        padding: 0 !important;
        + .backdrop {
            background-color: rgba($color: $black, $alpha: 0.95);
            width: 100%;
        }
    }
}

.under-menu-banner {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include responsive ("medium", "down") {
        background-position-x: 50%;
        @include pv-l;
    }

    .banner-columns {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include responsive ("medium", "down") {
            position: relative;
        }
    }

    .image {
        width: 100%;
        visibility: hidden;
    }
}

.text-under-first-scroll {
    background-color: $first-color;
    @include padding-xs;
    bottom: 0;
    position: absolute;
    width: 100%;
    h2 {
        @include h4;
        text-align: center;
        @include responsive ("medium", "down") {
            @include body-1;
        }
    }
}

.second-banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 $xxxll;
    h2 {
        @include h3;
        @include semibold;
    }
    p {
        @include h4;
        @include semibold;
    }

    @include responsive ("medium", "down") {
        padding: 0 $xs;
        height: 300px;
        h2 {
            @include h4;
        }
        p {
            @include body-1;
            @include semibold;
        }
    }
}

.contact-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include responsive ("medium", "down") {
        background-position: left;
    }
}
.curved-line {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.curved-line-mobile {
    position: absolute;
    left: 40px;
}

// HISTORY SECTION

.history-height {
    height: 450px;
    @include responsive ("medium", "down") {
        height: auto;
        @include mb-m
    }
}

.history-paragraph-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include responsive ("medium", "down") {
        h3{
            @include h4
        }
        p {
            @include body-2
        }
    }
}

.history-left-year{
    display: flex; 
    justify-content: flex-end; 
    align-items: center;
    @include responsive ("medium", "down"){
        justify-content: flex-start; 
    }
}

.history-right-year{
    display: flex; 
    justify-content: flex-start; 
    align-items: center;
}

.history-image {
    @include padding-m;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @include responsive ("medium", "down") {
        @include mt-xs;
        @include padding-0;
        @include pl-xs;
        justify-content: flex-start;
        img{
            width: 100px;
            height: 100px;
        }
    }

}

// HISTORY SECTION END

.main-pattern {
    &::before {
        @include pseudo-element-image($images + "/cms/home/pattern.svg", 50vh, 100%);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
        content: "";
        position: absolute;
        top: -50px;
        right: 0;
        @include responsive ("medium", "down") {
            display: none;
        }
    }

    .offset {
        @include responsive ("large", "up") {
            position: absolute;
            top: calc(100% + 20px);
            width: calc(100% - 32px);
            transform: translateY(-50%)
        }
    }
}

.applications-card {
    overflow: hidden;
    background-color: $blue-1;
    @include flex;
    @include justify-start;
    @include align-center;
    @include direction-column;
    @include padding-l;
}

.solution-card {
    overflow: hidden;

    .solution-title {
        position: absolute;
        top: calc(50% - 56px);
        left: 12%;
        font-size: 50px;
        line-height: 56px;
        color: $white;
        @include text-outline-1;
        mix-blend-mode: soft-light;
        @include semibold;

        @include responsive ("medium", "down") {
            font-size: 54px;
            line-height: 80px;
            top: calc(50% - 80px);
        }
    }
}

.solutions-featured {
        position: sticky;
        top: 0;
        transform-origin: center top;
        height: 90vh;
        transform: translateY(0px);
        text-align: center;
        display: flex;
        justify-content: center;
        background-color: $black;
    &.text-container  {
        align-items: center;
        p {
            font-size: 120px;
            line-height: 140px;
            @include semibold;
            text-align: center;
            overflow: hidden;
            @include ph-xs;
            word-break: break-all;
            @include responsive ("medium", "only") {
                font-size: 80px;
                line-height: 90px;
                top: 10px;
                left: 20px;
            }
            @include responsive ("small", "down") {
                font-size: 40px;
                line-height: 56px;
                top: 10px;
                left: 20px;
            }
        }
    }
    &.image-container {
        align-items: flex-start;
        img {
            width: 1000px;
            height: 80vh;
            object-fit: contain;
        }
    }
}

.solutions-first-scroll-container {
   @include mv-xxl;
}

.solution-card-margin {
    margin-top: 20rem;
    @include responsive ("small", "down") {
        @include mt-s;
    }
}


p {
    &.list-arrow-right {
        @include flex;
        @include justify-start;
        @include align-center;

        &:before {
            @include pseudo-element-image($icons + "/theme-icons/list-icon.svg", 20px, 20px);
            min-width: 20px;
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
            display: inline-block;
            @include mv-xxs;
            @include mr-xxs;
        }
    } 
}

.contact-form-container {
    height: 100vh;
}

.product-details {
    li {
        list-style-type: disc;
        @include pl-xxs;
        @include mb-xs;
        @include ml-xs;
    }
    &.text {
        p {
            @include pb-xxs;
        }
    }
}

.product-table {
    .tabs-panel {
        @include padding-0;

        .details {
            display: flex;
            justify-content:  space-between;
            @include border-bottom-blue-1;
            @include pt-xs;

            > p {
                width: 50%;

                @include responsive("large", "up") {
                    &:nth-child(2n) {
                        text-align: right;
                    }
                }
            }
        }
    }
    .tabs {
        background-color: $blue-2;
        border: none;
        @include mb-xs;
    }
    .tabs-content {
        background-color: $blue-2;
        border: none;
    }
    .tabs-title {
        background: none;
        color: $white;
        @include h4;
        @include medium;
        margin: $xxs $xs $xxs 0;
        padding: $xs $xs 0 0;
        cursor: pointer;
        position: relative;
        white-space: nowrap;

        &.selected {
            color: transparent;
            border-bottom: 5px solid $first-color;

            &::after {
                content: attr(data-text);
                color: $white;
                @include bold;
                position: absolute;
                left: 0;
            }
        }

        @include responsive("medium", "down") {
            @include body-1;
        }
    }
}

//typewrtite effect

.typewriter-banner {
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 $xxxll;
    text-align: center;
    @include responsive ("medium", "down") {
        padding: 0 $xs;
        height: 300px;
    }
}

#typewriterTitle {
    @include mb-m;

    .Typewriter__wrapper,
    .Typewriter__cursor {
        @include h2;
        @include medium;
        @include responsive ("medium", "down") {
            @include h4;
        }
    }
}

#typewriter {
    min-height: 160px;
    display: flex;
    align-items: center;
    .Typewriter__wrapper,
    .Typewriter__cursor {
        @include h3;
        @include medium;
        @include responsive ("medium", "down") {
            @include h4;
        }
    }
}

.video-container {
    .video-cover {
        width: 100%;
        height: auto;
    }

    .video-box {
        display: none;
        position: relative;
        padding-top: calc(100% * (9 / 16));

        .video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.channel-grid-text {
    overflow: hidden;
    @include responsive ("medium", "down") {
        height: auto;
    }
}

.quote {
    @include medium;
    @include h3;
    font-style: italic;
    border-left: 5px solid $first-color;
    @include pl-xs;

    @include responsive ("medium", "down") {
        @include h4;
    }
}

.category-banner-text {
    @include responsive ("small", "down") {
        p{
            font-size: 14px;
            line-height: 24px;
        }

        h2{
            font-size: 32px;
            line-height: 40px;
        }
    }
}