@use '../imports' as *;

#catalog-container {
    position: relative;

	&.loading {
		#catalog-wrapper {
			opacity: 0.5;
		}
	}

    .no-products {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

	#filters {
		// display: block;
		@include flex;
		@include justify-start;

		.filter-tag {
			// flex-grow: 1;
			position: relative;
			width: 100%;
			@include responsive ("medium", "down") {
				flex-grow: 0;
				border-bottom: 1px solid $blue-1;
			}
		}

		.close-mobile {
			position: absolute;
			top: 24px;
			right: 24px;
		}

		@include responsive ("medium", "down") {
			flex-direction: column;
			position: fixed;
			top: 0;
			bottom: 0;
			left: -100vw;
			height: 100%;
			background: $black;
			z-index: $overlay-z;
			width: 100%;
			padding: $xs;
			transition: transform 0.5s;
			overflow: scroll;
			flex-wrap: nowrap;
		}

		.dropdown {
			// &:not(:last-child) {
			// 	margin-bottom: $xxxs;
			// }

			.dropdown-toggle {
				@include flex;
				align-items: center;
				justify-content: space-between;
				height: 72px;
				background-color: $blue-2;
				width: 100%;
				cursor: pointer;
				border-left: 1px solid $blue-1;
				padding: 0 $xxs;
				border-top: 3px solid transparent;
				@include responsive ("medium", "down") {
					background-color: $black;
					border-left: none;
				}
				.dropdown-label {
					@include h4;
					@include first-font-medium;
					text-transform: capitalize;
				}

				.dropdown-icon {
					transition: transform 0.2s;
					@include mh-xxs
				}
			}

			.dropdown-toggle.open {
				background-color: $blue-1;
				border-top: 3px solid $first-color;
				.dropdown-icon {
					transform: rotate3d(1, 0, 0, 180deg);
				}

				@include responsive ("medium", "down") {
					@include border-no-top;
					@include border-no-right;
					@include border-no-left;
					background-color: rgba($color: $blue-1, $alpha: 0.6);
				}
			}

			.dropdown-content {
				max-height: 360px;
				width: 100%;
				overflow-y: auto;
				position: absolute;
				top: calc(100% + 1px);
				z-index: $menu-z;
				box-shadow: 2px 2px 10px 0px $box-shadow;
				background-color: $blue-1;
				
				ul {
					@include pv-xs;
					@include pr-xs;
					@include pl-xxs;
				}
				
				@include responsive ("medium", "down") {
					box-shadow: none;
					position: relative;
					top: 0;
					width: 100%;
					background: none;
				}

				.filters-list {
					overflow: hidden;
					width: 100%;
					.filter {
						@include mv-xxxs;
						p {
							text-transform: capitalize;
						}
					}
				}
			}
		}
	}

	#filters.open {
		@include responsive ("medium", "down") {
			transform: translateX(100vw);
		}
	}

	.current-filter {
		display: flex;
		align-items: baseline;
		@include mb-0;
		@include body-2;
		padding: 0 $xxs;
		color: $first-color;
		margin-right: $xxs;
		@include mb-0;
		text-transform: capitalize;

		@include responsive ("small", "down") {
			@include mt-xxs;
		}

		&:nth-last-child(2) {
			margin-right: $xs;
		}

		.icon {
			margin-right: $xs;
		}

		&.remove-all {
			border: none;
			background-color: transparent;
			text-decoration: underline;
			margin-right: 0;
			padding: 0;
		}
	}

	.section-title {
		font-size: 70px;
		line-height: 86px;
		@include bold;
		@include responsive ("small", "down") {
			@include h2;
			@include bold;
		}
	}

}
