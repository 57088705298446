@use '../imports' as *;

#product-container {
	.product-container.product-page {
		display: grid;
        grid-template-areas:
            'title        image'
            'tags         image'
            'description  image'
            'description  contacts';
        border: none;

        @include responsive("medium", "down") {
            grid-template-areas:
                'title        image'
                'tags         image'
                'tags         contacts'
                'description  description';
        }

        @include responsive("small", "down") {
            grid-template-areas:
                'title'
                'image'
                'tags'
                'description'
                'contacts';
        }

        .title {
            grid-area: title;
        }

        .tags {
            grid-area: tags;
            display: flex;
            a {
                &.tooltip {
                    position: relative;

                    &[data-title]  {
                        &:hover {
                            &:after {
                                background:$blue-2;
                                @include body-2;
                                text-transform: capitalize;
                                top:24px;
                                left:16px;
                                z-index: 1;
                                color:$white;
                                content:attr(data-title);
                                padding: $xxs;
                                position:absolute;
                            }
                        }
                    }
                }
            }
        }
        
        .product-details {
            grid-area: description;
        }

        .image {
            grid-area: image;
        }

        .contacts {
            grid-area: contacts;
        }
	}

	.description-title {
		@include h4;
		@include first-font-bold;
		margin: $xxs 0;
	}
	.description-text {
		color: $grey-2;
		* {
			@include body-2
		}
	}

    #details-tabs {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        overflow-x: auto;
        max-width: 100%;
        overflow-y: hidden;
    }
}