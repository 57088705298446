@use '../imports' as *;

.product-container:not(.expanded):not(.microbox):not(.product-page) {
	background-color: $blue-2;
	padding: $s;
	height: 100%;
	.tooltip {
		position: relative;

		&[data-title]  {
			&:hover {
				&:after {
					background:$blue-2;
					@include body-2;
					text-transform: capitalize;
					top:24px;
					left:16px;
					z-index: 1;
					color:$white;
					content:attr(data-title);
					padding: $xxs;
					position:absolute;
				}
			}
		}
	}
	
	@include responsive ("small", "down") {
		padding: $xs;
	}
	/* important necessario */
	.background-image.single-product-icons {
		@include responsive ("small", "down") {
			width: 35px!important;
			height: 35px!important;
		}
	}
	.product-subtitle {
		margin-top: $xxs;
		width: 100%;
		@include mb-xs;
	}

	.product-name {
		margin-top: $xxs;
		justify-content: flex-start;
		flex-direction: column;
		height: 56px;
		display: flex;
		width: 100%;
		@include mb-xs;
	}

	.product-img {
		picture + picture,
		img + img {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			z-index: 1;
		}

		&:hover,
		&:active {
			picture + picture,
			img + img {
				opacity: 1;
			}
		}
	}
}

.tags-container {
	position: absolute;
	left: 16px;
	bottom: 16px;
	z-index: 100;
	display: flex;
}

.categoryIconsContainer {
	display:flex; 
	justify-content: center;
	min-height: 40px;
	&.blog {
		position: absolute;
		bottom: 16px;
	}
}