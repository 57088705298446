@use "../../../node_modules/foundation-sites/scss/foundation" as foundation;

@use "imports" as *;
@use "general";
@use "flex";
@use "inputs";
@use "components";
@use "pages";
@use "styles";

$breakpoints: (
    small: 0px,
    medium: 640px + 1px,
    large: 1366px + 1px,
    xlarge: 1400px + 1px,
    xxlarge: 1440px + 1px,
);

// Swiper
@use "../../../node_modules/swiper/swiper-bundle";

// intlTelInput
@use "../../../node_modules/intl-tel-input/build/css/intlTelInput";

// Fonts
@import "../fonts/Montserrat/montserrat.css";

@include foundation.foundation-global-styles;
@include foundation.foundation-flex-grid;
@include foundation.foundation-forms;
//@include foundation-typography;
//@include foundation-button;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
// @include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation.foundation-visibility-classes;
//@include foundation-float-classes;
@include foundation.foundation-flex-classes;

.iti {
    width: 100%;
    margin: 0 0 1rem;
}

.iti__flag {
    background-image: url("/skins/current-skin/images/flags/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("/skins/current-skin/images/flags/flags@2x.png");
    }
}

.swiper-container:not(.swiper-container-initialized) {
    position: relative;
    height: 600px;
    overflow: hidden;
    
    > * {
        opacity: 0;
    }

    &::after {
        content: " ";
        position: absolute;
        top: calc(50% - #{$m} / 2);
        left: 50%;
        display: block;
        border-radius: 50%;
        width: 0;
        height: 0;
        margin: 8px;
        box-sizing: border-box;
        border: 32px solid $first-color;
        border-color: $first-color transparent $first-color transparent;
        animation: lds-hourglass 1.2s infinite;
    }

    @keyframes lds-hourglass {
        0% {
            transform: rotate(0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
        50% {
            transform: rotate(900deg);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        100% {
            transform: rotate(1800deg);
        }
    }
}
