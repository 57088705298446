@use '../imports' as *;

.archive-item-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
}

.archive-item-image {
    @include maintain-aspect-ratio(1600, 765);
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.archive-item-text {
    background-color: $blue-1;
    padding: $xs;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}
.blog-post-text {
    background: $blue-1;
    @include padding-s;
}
.blog-post-text-container {
    .blog-button {
        @include mt-xxs;
        background-color: $blue-2;
        @include padding-xxs;
        text-transform: uppercase;
        @include body-2;
        display: flex;
        align-items: center;
        &:after {
            @include pseudo-element-image($icons + "/general-icons/arrow-right-white.svg", 16px, 16px);
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
            display: inline-block;
            height: 14px;
            width: 14px;
            @include ml-xs;
        }
        &:hover {
            background: $first-color;
            transition: all ease 0.5s;
            box-shadow: inset 500px 0 0 0 $first-color;
        }
        span {
            @include medium;
        }
    }
}
.post-title {
    @include h4;
    @include bold;
}


#news-popup {
    margin: auto;
    background-color: $black;
    position: fixed;
    top: 0;
    bottom: 0;
    &::backdrop,
    & + .backdrop {
        background-color: rgba($color: $black, $alpha: 0.8);
    }
    > div {
        border: none;
        width: 40vw;
        background: $black;
        @include responsive ("medium", "only") {
            width: 80vw;
        }
        @include responsive ("small", "down") {
            width: 90vw;
            max-height: 45vh;
        }
    }
    .blog-post-text-container {
        left: 24px;
        top: 90px;
    }
    .close-button {
        top: 12px;
        right: 24px;
        position: absolute;
    }

    button {
        cursor: pointer;
    }
}

// single page blog

.blog-date {
    @include body-3;
    @include medium;
    @include text-outline-1;
    mix-blend-mode: soft-light;
    color: $white;
    font-size: 30px;
    line-height: 40px;
    @include semibold;
    @include responsive ("medium", "down") {
        font-size: 24px;
        line-height: 32px;
    }
    &.post-date {
        @include body-1;
    }
}


.blog-tab {
    @include mr-xs; 
    @include pv-xxs; 
    text-align: center;
    &.active {
        color: $first-color;
        border-bottom: 5px solid $first-color;
        @include bold;
    }
    white-space: nowrap;
}

.blog-tag {
    text-transform: uppercase;
    color: $grey-2;
    @include body-3;
    @include mb-0;
}

.news-tab-container {
    display: flex; 
    width: 100%;
    overflow: auto;
    @include body-2;
    @include mb-l; 
    @include upper;

    @include responsive("medium", "down"){
        transform: translateX(0)
    }
}

.case-studies-title {
    @include h3;
    @include mt-xs;
    @include background-text($gradient-first);
}