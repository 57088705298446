@use '../imports' as *;

header {
    position: sticky;
    top: 0;
    padding: $xs 0;
    background-color: $white;
    z-index: $header-z;
    transition: background-color .4s;
    #menu-toggle {
        background-color: $first-color;
        width: 96px;
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.open {
            .open-menu {
                display: none;
            }
        }
        &:not(.open) {
            .close-menu {
                display: none;
            }
        }

        @include responsive ("medium", "down"){ 
            width: 70px;
            height: 70px;
        }
    }

    .header-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #profile-icon-container {
        position: relative;

        .dropdown {
            display: none;
            position: absolute;
            top: 100%;
            right: -30px;

            ul {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: $xxs 0;
                background-color: $blue-2;

                li {
                    padding: $xxxs $xs;
                    width: 100%;
                    
                    a, form, [type=submit] {
                        cursor: pointer;
                        @include body-3;
                        text-align: center;
                        display: inline-block;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                    }
    
                    &:hover {
                        background-color: $blue-1;
                    }
                }
            }
        }

        &:hover {
            .dropdown {
                display: block;
            }
        }
    }

}