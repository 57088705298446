// common paths
$skin: "/skins/eltra";
$images: $skin + "/images";
$icons: $images + "/icons";

// *** DO NOT REMOVE THIS ***
//$$ variables-hook-start $$

// colors
$black: #090F13;
$black-2: #09151C;
$blue-1: #0E232E;
$blue-2: #05161F;
$dark-grey: #474747;
$grey-1: #8C8C8C;
$grey-2: #C0C0C0;
$light-grey: #F3F3F3;
$line-color: #C1C1C1;
$white: #FFFFFF;
$first-color: #17ABE6;
$second-color: #09CFDB;
$third-color: #07E89E;
$fourth-color: #FFB300;
$fifth-color: #CE2A2A;
$danger: #F91E43;
$success: #41D682;
$gradient-first: linear-gradient(358deg,#07e89e,#17abe6);

// borders
$radius-small: 4px;
$radius-medium: 8px;
$radius-large: 16px;

//$$ variables-hook-end $$
// *** DO NOT REMOVE THIS ***

$box-shadow: rgba(0, 73, 183, 0.06);
$box-shadow-hover: rgba(0, 73, 183, 0.15);

@function hover-color($color) {
	@return lighten($color, 10%);
}

// for third party elements
$grey-paypal: #eee;

// share buttons
$blue-facebook: #1877f2;
$blue-google: #4285f4;
$green-whatsapp: #3fc351;

// z-indexes
$messagebox-z: 3000;
$popup: 2500;
$overlay-z: 2000;
$header-z: 1500;
$menu-z: 1000;
$cookie-law-z: 3000;
$under-menu-banner-z: 100;

// fonts
$first-font: "Montserrat", sans-serif;